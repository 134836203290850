import { StripeAccountData, StripePayMethods } from './stripe-subscription.types';
import { WhatsappwebAccount } from './whatsappweb-account.types';

export type UserAccount = {
    account_id: number;
    account_name: string;
    image: string;
    industry: IndustryOpt[];
    country: CountryOpt;
    currency: string;
    stripeAccount: StripeAccount;
    subscription: SubscriptionData;
    whatsappweb_account: WhatsappwebAccount;
    zone_id: string;
};

export type IndustryOpt = {
    id: number;
    name?: string;
};

export type StripeAccount = StripeAccountData & {
    installment_payment_methods: StripePayMethods[] | null;
    redirect_url: string;
    tax_id: string;
};

export type StripeAccountUpdateDTO = {
    installments_enabled: boolean;
    installments: number[];
    installment_payment_methods: StripePayMethods[];
    payment_methods: StripePayMethods[];
    redirect_url: string;
};

export type SubscriptionData = {
    status: SubscriptionStatus;
    days_until_due: number;
    start_at: string;
    period_start: string;
    period_end: string;
    canceled_at: string | null;
    cancellation_reason: string | null;
};

export const SUB_STATUS = {
    INCOMPLETE: 'incomplete',
    INCOMPLETE_EXPIRED: 'incomplete_expired',
    TRIAL: 'trialing',
    ACTIVE: 'active',
    PAST_DUE: 'past_due',
    CANCELED: 'canceled',
    UNPAID: 'unpaid',
};

export type SubscriptionStatus = (typeof SUB_STATUS)[keyof typeof SUB_STATUS];

export type CountryOpt = {
    code: string;
    name: string;
    phone_prefix: string;
};

export type CountryFull = CountryOpt & {
    currencies: string[];
    default_currency: string;
    payment_methods: string[];
};

export interface ProfileUpdateDTO {
    name: string;
    lastname?: string;
    country: string;
    currency: string;
    timezone?: string;
}
export interface ProfileUpdateResponse {
    user_id: string;
    email: string;
    name: string;
    lastname: string;
    account_id: string;
}
