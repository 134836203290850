import { HttpClient } from '@angular/common/http';
import { Injectable, computed, inject, signal } from '@angular/core';
import { Router } from '@angular/router';
import { Card, PaymentMethod } from '@core/models/IAdmin';
import { SUB_STATUS } from '@core/models/admin/admin-account.types';
import { environment } from '@environments/environment';
import { Invoice } from '@features/settings/I-account';
import { ToastrService } from 'ngx-toastr';
import { finalize } from 'rxjs';
import { AccountService } from './account.service';

@Injectable({ providedIn: 'root' })
export class SubscriptionService {
    readonly #router = inject(Router);
    readonly #http = inject(HttpClient);
    readonly #toastr = inject(ToastrService);
    readonly #accS = inject(AccountService);

    paymentMethod = signal<Card | null>(null);
    invoices = signal<Invoice[]>([]);
    isLoading = {
        cancel: signal(false),
        payMethod: signal(false),
        invoices: signal(false),
    };

    showStatusWarning = computed(() => {
        const status = this.#accS.account()?.subscription?.status;
        const daysLeft = this.#accS.account()?.subscription?.days_until_due;

        return this.shouldShowStatus(status, daysLeft);
    });

    getPaymentMethod$() {
        const url = environment.api_base + '/admin/stripe/payment_method';
        return this.#http.get<PaymentMethod>(url);
    }

    reqPaymentMethod() {
        this.isLoading.payMethod.set(true);

        this.getPaymentMethod$()
            .pipe(finalize(() => this.isLoading.payMethod.set(false)))
            .subscribe({
                next: res => this.paymentMethod.set(res?.card),
                error: err => err?.status !== 403 && this.#toastr.error(err?.message),
            });
    }

    getInvoices$() {
        const url = environment.api_base + '/admin/stripe/invoices';
        return this.#http.get<Invoice[]>(url);
    }

    reqInvoices() {
        this.isLoading.invoices.set(true);

        this.getInvoices$()
            .pipe(finalize(() => this.isLoading.invoices.set(false)))
            .subscribe({
                next: res => this.invoices.set(res),
                error: err => err?.status !== 403 && this.#toastr.error(err?.message),
            });
    }

    reqSubscription() {
        this.#accS.getUserAccounts();
        this.reqPaymentMethod();
        this.reqInvoices();
    }

    updateSubscription(seshId: string) {
        const url = environment.api_base + '/admin/stripe/update_subscription?session_id=' + seshId;

        this.isLoading.payMethod.set(true);
        this.isLoading.invoices.set(true);

        this.#http
            .get(url)
            .pipe(finalize(() => this.reqSubscription()))
            .subscribe({
                next: () => this.#toastr.success('Billing information updated successfully!'),
                error: () =>
                    this.#toastr.error(
                        'Please refresh the page or contact support',
                        'Unable to update billing information'
                    ),
            });
    }

    cancelSubscription(reason?: string) {
        const url =
            environment.api_base +
            '/admin/stripe/cancel_subscription?cancellation_reason=' +
            reason;

        this.isLoading.cancel.set(true);
        return this.#http
            .get(url)
            .pipe(finalize(() => this.isLoading.cancel.set(false)))
            .subscribe({
                next: () => {
                    this.#accS.getUserAccounts();
                    this.reqPaymentMethod();
                    this.reqInvoices();

                    this.#toastr.info('Successfully canceled your subscription');
                    this.#router.navigate(['/main/account/settings/subscription'], {
                        queryParams: { modal: 1 },
                    });
                },
                error: err => this.#toastr.error(err?.message),
            });
    }

    private shouldShowStatus(status?: string, daysLeft?: number) {
        return (
            status === SUB_STATUS.INCOMPLETE_EXPIRED ||
            status === SUB_STATUS.PAST_DUE ||
            status === SUB_STATUS.CANCELED ||
            status === SUB_STATUS.UNPAID ||
            (status === SUB_STATUS.TRIAL && daysLeft! < 0)
        );
    }
}
